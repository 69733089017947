<template>
    <v-container fluid class="px-8">
        <!-- 课程基本内容和状态 -->
        <v-card class="course-info mb-12" elevation="2">
            <v-img
                :src="getImgUrl4Type(course.cover, course.course_type)"
                height="125"
                width="200"
                max-width="200"
                class="grey darken-4 me-5"
            ></v-img>

            <div class="course-desc">
                <!-- <div class="desc-col two"> -->
                <div class="desc-col one">
                    <div class="mb-2 desc-row">
                        <div class="font-weight-medium desc-name">
                            课程名称：
                        </div>
                        <div class="text">{{ course.name }}</div>
                    </div>
                    <div class="mb-2 desc-row">
                        <div class="font-weight-medium desc-name">
                            课程说明：
                        </div>
                        <div class="text">{{ getText(course.desc, "暂无说明") }}</div>
                    </div>
                </div>
                <div class="desc-col two">
                    <div class="mb-2 desc-row">
                        <div class="font-weight-medium desc-name">
                            难度级别：
                        </div>
                        <div class="rating">
                            <v-rating
                                :value="course.level"
                                color="yellow darken-3"
                                background-color="grey darken-1"
                                empty-icon="$ratingFull"
                                dense
                                readonly
                            ></v-rating>
                        </div>
                    </div>
                    <div class="mb-2 desc-row">
                        <div class="font-weight-medium desc-name">
                            知识要点：
                        </div>
                        <div class="text">{{ course.points }}</div>
                    </div>
                    <!-- <div class="mb-2 desc-row">
                        <div class="font-weight-medium desc-name">状态：</div>
                        <div class="text">{{ course.status }}</div>
                    </div> -->
                </div>
            </div>
            <div class="operate">
            </div>
        </v-card>

        <!-- 当前课程小节列表 -->
        <v-data-table
            :headers="sectionHeader"
            :items="sections"
            item-key="id"
            class="elevation-2 mb-12"
            :disable-pagination="true"
            :hide-default-footer="true"
        >
            <template v-slot:body="props">
                <draggable 
                    :list="props.items" 
                    tag="tbody"
                    @change="sectionListChange"
                    handle=".handle"
                >
                    <tr
                        v-for="(item, index) in props.items"
                        :key="index"
                        class="row-height"
                    >
                        <td>{{ index + 1 }}</td>
                        <td class="td-line font-weight-medium">
                            <div class="active-name"
                                @click.stop="goto(item.section_id, item.sub_type)"
                            >
                                {{ item.name }}
                            </div>
                        </td>
                        <td class="font-weight-medium">
                            <v-chip
                                :color="getSectionTypeColor(item.type)"
                                dark
                            >
                                {{ getTypeName(item.type) + getSubTypeName(item.sub_type) }}
                            </v-chip>
                        </td>
                        <td class="table-center">{{ item.desc }}</td>
                        <!-- <td>{{ item.status }}</td> -->
                        <td>
                            <v-btn
                                tile
                                dark
                                color="orange"
                                class="font-weight-bold text-body-1"
                                style="margin: 0;"
                                max-height="36"
                                @click.stop="goto(item.section_id, item.sub_type)"
                            >
                                <v-icon left> mdi-eye </v-icon>
                                查看
                            </v-btn>
                        </td>
                    </tr>
                </draggable>
            </template>
        </v-data-table>

    </v-container>
</template>

<script>
import draggable from "vuedraggable";
import {
    getAdminCourse,
    getAdminSectionList,
    updateAdminSectionListIndex,
} from "@/api/admin";
import { getTypeColor, gotoSection, getTypeName, getSubTypeName, getSectionTypeColor } from '@/utils/common';
import { getText, getImgUrl, getTypeFirstLetter } from "@/utils/util";

export default {
    name: "TopicPreviewCourse",

    data() {
        return {
            cid: "",
            course: {},

            defaultImg: {
                scratch: "35545445d836064a7fd834c58ce4",
                python: "e3db075709616499fe79760c35cd",
                robot: "8740291eaeafea6bf72e96571d63",
            },

            sections: [],
            sectionHeader: [
                {
                    text: "序号",
                    value: "index",
                    sortable: false,
                    align: "center",
                    width: 80,
                },
                { 
                    text: "小节名称",
                    value: "name", 
                    align: "center",
                    sortable: false,
                },
                {
                    text: "类型",
                    value: "type",
                    align: "center",
                    sortable: false,
                    width: 120,
                },
                { 
                    text: "小节说明",
                    value: "desc", 
                    align: "center",
                    sortable: false,
                },
                // {
                //     text: "状态",
                //     value: "status",
                //     sortable: false,
                //     align: "center",
                // },
                {
                    text: "操作",
                    value: "operate",
                    sortable: false,
                    align: "center",
                    width: 140,
                },
            ],

            stats: [],
            statHeader: [
                {
                    text: "序号",
                    value: "index",
                    sortable: false,
                    align: "center",
                    width: 60,
                },
                { 
                    text: "练习名称",
                    value: "name", 
                    align: "center",
                    sortable: false,
                },
                {
                    text: "类型",
                    value: "type",
                    align: "center",
                    sortable: false,
                },
                { 
                    text: "主要内容", 
                    value: "desc", 
                    sortable: false 
                },
                { 
                    text: "练习人数", 
                    value: "user_count",
                    align: "center", 
                    sortable: false,
                },
                { 
                    text: "完成人数", 
                    value: "user_count",
                    align: "center", 
                    sortable: false,
                },
                // {
                //     text: "其他",
                //     value: "operate",
                //     sortable: false,
                //     align: "center",
                //     width: 160,
                // },
                { text: '', value: 'data-table-expand' },
            ],
            isViewStat: false,
            singleExpand: true,
            expanded: [],

            students: [],

            fromPath: '',
        };
    },
    created() {
    },
    mounted() {
    },
    activated() {
        if (this.$route.query.id) {
            this.cid = this.$route.query.id;
        }
        
        let isFromIde = false
        if (this.fromPath.substring(0, '/editor/'.length) == '/editor/') {
            isFromIde = true
        }
        if (!isFromIde) {
            this.reset()
        }
        this.loadData()
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            // 通过 vm 访问组件实例,将值传入fromPath
            vm.fromPath = from.path;
        });
    },

    methods: {
        getText,
        getTypeColor,
        getTypeFirstLetter,
        gotoSection,
        getTypeName,
        getSubTypeName,
        getSectionTypeColor,
        getImgUrl4Type(imgId, type) {
            if (imgId == "" || imgId == null || imgId == undefined) {
                if (type == "SCRATCH") {
                    return getImgUrl(this.defaultImg.scratch)
                } else if (type == "PYTHON") {
                    return getImgUrl(this.defaultImg.python)
                } else if (type == "ROBOT") {
                    return getImgUrl(this.defaultImg.robot)
                }
                return ""
            }
            return getImgUrl(imgId)
        },
        reset() {
            this.isViewStat = false
            this.students = []
            this.stats = []
        },
        loadData() {
            // 获取课程信息
            getAdminCourse(this.cid).then((response) => {
                // console.log(response.data);
                this.course = response.data;

            }).catch(function (err) {
                console.log(err);
            });

            // 获取课程小节列表
            getAdminSectionList(this.cid).then((response) => {
                // console.log(response.data);
                this.sections = response.data;

            }).catch(function (err) {
                console.log(err);
            });
        },
        sectionListChange(e) {
            if (e.moved) {
                let oldIndex = e.moved.oldIndex
                let newIndex = e.moved.newIndex

                // 保存元素
                let tmp = this.sections[oldIndex]
                // 将元素从旧位置删除
                this.sections.splice(oldIndex, 1)
                // 将元素添加到新位置
                this.sections.splice(newIndex, 0, tmp)
            }

            this.updateAdminSectionListIndex()
        },
        async updateAdminSectionListIndex() {
            let sectionIds = []
            for (let i = 0; i < this.sections.length; i++) {
                sectionIds.push(this.sections[i].section_id)
            }

            await updateAdminSectionListIndex({
                id: this.cid,
                sectionIds: sectionIds
            })
        },
        goto(sid, subType) {
            this.gotoSection(this.course.cid, sid, subType, 'classroom', 'push')
        },
    },
    components: {
        draggable,
    },
};
</script>

<style lang="scss" scoped>
.alert-area {
    position: absolute;
    width: 100%;
    z-index: 10;
    top: 0;
}
.handle {
    cursor: move;
    
}
.row-pointer {
    cursor: move;
}
// 去掉v-card默认的上下的margin
.v-card {
    margin: 0;
}

.add-btn-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.course-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .course-desc {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .desc-col {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin: 0 20px;

            &.one {
                flex: 1;
            }
            &.two {
                flex: 2;
            }
        }
        .desc-row {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 35px;
            .desc-name {
                width: 70px;
                min-width: 70px;
            }
        }
        .text {
            text-align: left;
            font-weight: 300;
            font-size: 14px;
        }
        .rating {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .operate {
        margin: 0 20px;
    }
}
.table-left {
    text-align: left;
}
.table-center {
    text-align: center;
}
.td-line {
    text-align: center;
    display: flex;
    justify-content: center;
    height: unset !important;
    .active-name {
        padding: 8px 25px;
        border-radius: 50px;
        // background-color: #efefef;
        background-color: #fff;
        &:hover {
            cursor: pointer;
            background-color: #fff;
            border-radius: 50px;
        }
    }
}
.expendContent {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
</style>
